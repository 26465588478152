import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { VTimePicker } from 'vuetify/labs/VTimePicker'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import store from './store'

const vuetify = createVuetify({
  components: {
    ...components,
    VTimePicker,
  },
  directives,
})

const firebaseApp = initializeApp({
  apiKey: 'AIzaSyCFH1a5DHaddzzI_kCT8yCW9qjDpmVUL0A',
  authDomain: 'cheap-marketing-officer.firebaseapp.com',
  projectId: 'cheap-marketing-officer',
  storageBucket: 'cheap-marketing-officer.appspot.com',
  messagingSenderId: '463105192637',
  appId: '1:463105192637:web:989606534e2c4a265cbdd4',
})

window.cheapMarketingOfficer = {}

var isInitialized = false
const auth = getAuth()
onAuthStateChanged(auth, user => {
  if (user) {
    // User is signed in
    window.cheapMarketingOfficer.firebaseUser = user
  } else {
    // User is signed out
    window.cheapMarketingOfficer.firebaseUser = undefined
  }
  if (!isInitialized) {
    init()
    isInitialized = true
  }
})
function init() {
  const app = createApp(App)
  app.use(router)
  const axiosInstance = axios.create({ baseURL: '/api' })
  // Add a request interceptor to include the ID token
  axiosInstance.interceptors.request.use(
    async config => {
      const auth = getAuth(firebaseApp)
      const user = auth.currentUser
      if (user) {
        const idToken = await user.getIdToken()
        config.headers.Authorization = `Bearer ${idToken}`
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )
  app.use(VueAxios, axiosInstance)
  app.use(vuetify)
  app.use(store)
  app.mount('#app')
}
