<template>
  <div id="app">
    <v-snackbar
      v-model="$store.state.snackbarIsOpen"
      timeout="4000"
      position="fixed"
      location="top"
      :color="$store.state.snackbarColor"
      min-width="100"
      :max-width="$store.getters.isMobile ? 250 : undefined"
    >
      {{ $store.state.snackbarText }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="$store.commit('closeSnackbar')">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0;
}
body {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  font-family: 'inter', sans-serif;
  color: #0f172a;
}
h1 {
  font-size: 60px;
  line-height: 1.3em;
}
h2 {
  font-size: 48px;
}
h3 {
  font-size: 2em;
}
h4 {
  font-size: 1.5em;
}
h5 {
  font-size: 1.25em;
}
h6 {
  font-size: 1em;
}
p {
  font-family: 'inter', sans-serif;
  font-size: 16px;
  line-height: 1.3;
  color: #475569;
  font-weight: 300;
}
</style>
