import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home-View',
    component: () => import('./views/Home-View.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('./views/Admin.vue'),
  },
  {
    path: '/about',
    name: 'About-View',
    component: () => import('./views/About.vue'),
  },
  {
    path: '/preview/:id',
    name: 'Preview',
    meta: {
      requiresAuth: true,
    },
    component: () => import('./views/Preview.vue'),
  },
  {
    path: '/linkedin-content-feed/:id?',
    name: 'LinkedIn-Content-Feed',
    meta: {
      requiresAuth: true,
    },
    component: () => import('./views/LinkedIn-Content-Feed.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
