import { createStore } from 'vuex'

// Create a new store instance.
const store = createStore({
  state() {
    return {
      snackbarIsOpen: false,
      snackbarColor: 'success',
      snackbarText: '',
      isMobile: false,
    }
  },
  getters: {
    isMobile() {
      return window.innerWidth < 796
    },
  },
  mutations: {
    setSnackbar(state, snackbarProps) {
      state.snackbarColor = snackbarProps.type
      state.snackbarText = snackbarProps.message
      state.snackbarIsOpen = true
    },
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile
    },
    closeSnackbar(state) {
      state.snackbarIsOpen = false
    },
  },
})

export default store
